import React, { useState } from 'react';
import Download from './Download';

function Verify(props) {
    
    const [email, setEmail] = useState(null);
    const [isBusy, setIsBusy] = useState(false);
    const [isValid, setIsValid] = useState(null);
    const [verificationHash, setVerificationHash] = useState(null);

    const verify = (e) => {
        e.preventDefault();
        setIsValid(true);
        if (email === null || email === "" || email.indexOf("@") === -1 || email.indexOf(".") === -1) {
            setIsValid(false);
            return false;
        }
        setIsBusy(true);
        makeApiCall();
    }

    const makeApiCall = () => {

        if (isBusy) return;

        fetch(props.endpoint+"/reports/verify", {
            method: 'POST',
            body: JSON.stringify({
              email: email
            }),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error("Invalid response !!!");
            }
        })
        .then(json => {
            console.log(json);
            setIsBusy(false);
            setVerificationHash(json.verificationHash);
        })
        .catch(error => {
            setIsBusy(false);
            setIsValid(false);            
            console.log(error);
        });            
    }

    return (

      <div className="row">
        <form onSubmit={verify}>
            <div className="col">
                <label htmlFor="emailAddress" className="form-label">Email address</label>
                <div className="input-group has-validation mb-3">
                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                    <input 
                        type="text" 
                        className={isValid === null ? "form-control" : (isValid === true ? "form-control is-valid" : "form-control is-invalid")} 
                        id="emailAddress" 
                        aria-describedby="inputGroupPrepend" 
                        onChange={(e) => setEmail(e.target.value)}
                        required />
                    <div className="invalid-feedback">Ooops, please enter valid email address.</div>
                </div>

                { 
                verificationHash === null &&
                <button type="submit" className={isBusy ? "btn btn-primary disabled" : "btn btn-primary"}>Submit</button>
                }
            </div>

            {
            isBusy && 
            <div className="col text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }
        </form>
        { 
        verificationHash !== null && verificationHash !== "" &&
        <Download endpoint={props.endpoint} email={email} verificationHash={verificationHash} />
        }

      </div>

    );
  }
  
  export default Verify;
  