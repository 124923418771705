import React, { useState } from 'react';

function Download(props) {
    
    const [isBusy, setIsBusy] = useState(false);
    const [isValid, setIsValid] = useState(null);
    const [packageId, setPackageId] = useState(null);
    const [packageName, setPackageName] = useState(null);
    const [verificationCode, setVerificationCode] = useState(null);
    const [fileRef, setFileRef] = useState(null);

    const verify = (e) => {
        e.preventDefault();
        setIsValid(true);

        if (packageId === null || 
            packageId === "" || 
            packageName === null || 
            packageName === "" || 
            verificationCode === null ||
            verificationCode === "") {

            setIsValid(false);
            return false;
        }

        setIsBusy(true);
        makeApiCall();
    }

    const makeApiCall = () => {

        if (isBusy) return;

        fetch(props.endpoint+"/reports/download-event-data", {
            method: 'POST',
            body: JSON.stringify({
              email: props.email,
              verificationCode: verificationCode,
              verificationHash: props.verificationHash,
              packageId: Number(packageId),
              packageName: packageName
            }),
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/octet-stream'
            }
        })
        .then(response => {
            if (!response.ok) {
                if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
                    response.json().then(console.log);
                }
                throw new Error('Unable to download file.')
            }
            return response.blob(); 
        })
        .then(file => {
            setIsBusy(false);
            setFileRef(window.URL.createObjectURL(file));
        })
        .catch(error => {
            setIsBusy(false);
            setIsValid(false);            
            console.log(error);
        });            
    }

    return (

        <form onSubmit={verify}>
            <div className="col">
                <label htmlFor="verificationCode" className="form-label">Verification code (you will receive it via email shortly)</label>
                <div className="input-group has-validation mb-3">
                    <span className="input-group-text" id="inputGroupPrepend">123ABC</span>
                    <input 
                        type="text" 
                        className={isValid === null ? "form-control" : (isValid === true ? "form-control is-valid" : "form-control is-invalid")} 
                        id="verificationCode" 
                        aria-describedby="inputGroupPrepend" 
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required />
                    <div className="invalid-feedback">Make sure verification code is valid.</div>
                </div>

                <label htmlFor="packageId" className="form-label">Package Id</label>
                <div className="input-group has-validation mb-3">
                    <span className="input-group-text" id="inputGroupPrepend">123</span>
                    <input 
                        type="text" 
                        className={isValid === null ? "form-control" : (isValid === true ? "form-control is-valid" : "form-control is-invalid")} 
                        id="packageId" 
                        aria-describedby="inputGroupPrepend" 
                        onChange={(e) => setPackageId(e.target.value)}
                        required />
                    <div className="invalid-feedback">Make sure package ID is valid and exists.</div>
                </div>

                <label htmlFor="packageName" className="form-label">Package Name</label>
                <div className="input-group has-validation mb-3">
                    <span className="input-group-text" id="inputGroupPrepend">My Event</span>
                    <input 
                        type="text" 
                        className={isValid === null ? "form-control" : (isValid === true ? "form-control is-valid" : "form-control is-invalid")} 
                        id="packageName" 
                        aria-describedby="inputGroupPrepend" 
                        onChange={(e) => setPackageName(e.target.value)}
                        required />
                    <div className="invalid-feedback">Make sure package name is valid and matches package ID.</div>
                </div>

                {
                fileRef === null &&
                <button type="submit" className={isBusy ? "btn btn-primary disabled" : "btn btn-primary"}>Generate Report</button>
                }

            </div>

            {
            isBusy && 
            <div className="col text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            }

            {
            fileRef !== null &&
            <div className="col text-center mb-3">
                <a href={fileRef} download="event-data.zip" className="btn btn-success">Download File</a>
                <div className="alert alert-danger m-3" role="alert">
                    <b>Important!</b> Please make sure you close this browser window once you've downloaded the report file.
                </div>
            </div>
            }
        </form>

    );
  }
  
  export default Download;
  